import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout/Layout";
import { SEO } from "../components/Seo";
import seoData from "../data/seoData.json";

const Index = () => (
  <Layout>
    <div className="absolute z-0 w-[100vw] max-w-[100%] ">
      <div className="bgfilter" />
      <StaticImage
        src="../images/whypeople_imagen11.jpg"
        objectFit="cover"
        alt="whypeople"
        style={{
          width: "100vw",
          maxWidth: "100%",
          position: "fixed",
          zIndex: "-5",
          minHeight: "100vh",
        }}
      />
    </div>
    <div className="min-h-[calc(100vh-10.5rem)] flex justify-center pb-10 relative z-[5]">
      <div className="container mx-auto px-8 lg:flex mt-[20vh]">
        <div className="text-left lg:text-left lg:w-1/2">
          <StaticImage
            src="../images/logos/logo_personas_resultados.png"
            placeholder="blurred"
            height={128}
            formats={["auto", "webp", "avif"]}
            alt="WhyPeople. Ir a la portada"
            transformOptions={{ fit: "cover", cropFocus: "attention" }}
          />

          <p className="text-sm sm:text-lg lg:text-2xl 2xl:text-3xl 3xl:text-4xl 4xl:text-5xl 6xl:text-6xl mt-6 text-primary-darkest">
            Somos una consultora estratégica de recursos humanos cuya razón de
            ser es contribuir al logro de los objetivos de negocio a través de
            la mejora en la gestión de las personas.
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default Index;

export const Head = () => (
  <SEO>
    <script type="application/ld+json">{JSON.stringify(seoData)}</script>
  </SEO>
);
